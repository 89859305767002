html,
body,
main,
#next {
  padding: 0;
  margin: 0;
  background-color: whitesmoke;
  height: 100vh;
}

#__next {
  height: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  touch-action: manipulation;
}

#main {
  width: 100vw;
}
